.dialog-background {
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.872);
  padding: 1.6rem;
  /* display: none; */
  justify-content: center;
  align-items: center;
  transform: translateY(-100vh);
  transition: 0.5s ease-in-out;
  /* border-radius: 300000px; */
  transition-delay: 0.4s;
  opacity: 0;
}
.meeting-open-background {
  opacity: 1;
  transition: 0.5s ease-in-out;
  transform: translateY(0vh);
  transition-delay: 0s;
}
.sc-close {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  border-radius: 5000rem;
  color: rgb(255, 255, 255);
  display: flex;
  padding: 0.56rem;
  background-color: rgba(0, 0, 0, 0.339);
  cursor: pointer;
  transition: all 0.5s ease;
}
.sc-close:hover {
  transition: all 0.5s ease;
  background-color: rgba(0, 0, 0, 0.739);
}
.dialog {
  opacity: 0;
  background-color: rgb(34, 38, 41);
  border-radius: 0.8rem;
  min-width: 70%;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 100vw), -50%);
  z-index: 999999;
  transition-delay: 0s;
  overflow-y: scroll;
  max-height: 95%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.align-center {
  width: 100%;
}
.meeting-open {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition-delay: 0.3s;
}
.sc-h1 {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.64rem;
  text-align: center;
  padding: 1.6rem;
}
.sc-h2 {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.44rem;
  text-align: center;
  padding: 1.6rem;
}
.dialog::-webkit-scrollbar {
  display: none;
}
.dialog form {
  padding: 1.6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.dialog form div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.dialog form div label {
  color: rgb(46, 123, 255);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.4rem;
}
.dialog form div span {
  color: white;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.95rem;
  margin-top: -0.8rem;
}

.dialog form div input {
  margin-bottom: 0.95rem;
  padding: 0.48rem;
  border-radius: 4px;
  background-color: rgb(9, 9, 10);
  border: none;
  caret-color: rgb(46, 123, 255);
  color: rgb(181, 181, 181);
  font-size: 0.8rem;
}
.dialog form div input:focus {
  outline: none;
  border-bottom: 1px rgb(46, 123, 255) solid;
}
.radio-sc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
.radio-sc section {
  display: flex;
  align-items: center;
  width: 25%;
}
.radio-sc input {
  display: none;
}
.radio-sc label {
  text-align: center;
  padding: 0.8rem;
  margin: 0.8rem;
  color: rgb(46, 123, 255);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgb(46, 123, 255);
  transition: all 0.5s ease;
}
.radio-sc label:hover {
  background-color: rgb(22, 22, 22);
  transition: all 0.5s ease;
}
.radio-sc input:checked ~ label {
  background-color: rgb(9, 9, 10);
  transition: all 0.5s ease;
  border: 1px solid rgb(9, 9, 10);
}
.dialog button {
  transition: all 0.5s ease;
  width: 100%;
  margin-top: 3.2rem;
  background-color: rgb(9, 9, 10);
  padding: 0.68rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: rgb(46, 123, 255);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog button:hover {
  transition: all 0.5s ease;
  background-color: black;
}

.dialog button span {
  margin-left: 0.68rem;
}
@media (max-width: 820px) {
  .dialog::-webkit-scrollbar {
    display: none;
  }

  .dialog {
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: calc(100% - 3.5rem);
    min-width: calc(100% - 3.5rem);
  }
  .sc-h1 {
    font-size: 1.8rem;
    margin-top: 3rem;
    padding: 1rem;
    padding-bottom: 0;
  }
  .sc-h2 {
    font-size: 1.6rem;
    padding: 0rem;
    padding-top: 1rem;
  }
  .sc-close {
    padding: 0.5rem;
  }
  .dialog form div {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .radio-sc section {
    width: 100%;
  }
  .radio-sc label {
    margin: 0;
    margin-top: 0.5rem;
  }
  .radio-sc {
    margin-top: 1.5rem;
  }
}
