@import "./css/dialog.css";
@import "./css/navbar.css";
@import "./css/home.css";
@import "./css/services.css";
@import "./css/about.css";
@import "./css/contact.css";
@import "./css/team.css";
@import "./css/projects.css";
@import "./css/preloader.css";
@import "./css/mob-nav.css";
@import "./css/profile.css";
@import "./css/styles.css";
@import "./css/quote.css";
body {
  background-color: black;
}
