@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500&family=Poppins:wght@500;@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Roboto:wght@100;200;400;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.testemonials {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 3.5rem;
  padding-top: 0;
  gap: 2rem;
  transform: translateY(-1rem);
  position: relative;
}
.testemonial {
  width: 28vw;
  /* background: rgba(255, 255, 255, 0.26); */
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  /* From https://css.glass */
  background: rgb(47, 50, 53);
  border-radius: 4px;
  padding: 0.5rem;
  border-bottom: 2px rgb(46, 123, 255) solid;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  /* border-radius: 4px; */
}
.test-person {
  display: flex;
  justify-content: center;
}
.test-info {
  display: flex;
  justify-content: center;
  padding: 0.8vw;
}
.test-img {
  background-color: blue;
  height: 3vw;
  width: 3vw;
  border-radius: 1111vw;
}
.test-text div {
  color: #eee;
  font-weight: 400;
  font-size: 0.8rem;
  /* font-style: italic; */
  /* text-align: center; */
  padding: 1rem;
  font-family: "Roboto", sans-serif;
  padding-bottom: 2rem;
}
.test-text span {
  color: #eee;
  font-weight: 100;
  font-size: 0.8rem;
  /* font-style: italic; */
  text-align: center;
  font-family: "Roboto", sans-serif;
  display: block;
}
.test-text b {
  color: rgb(46, 123, 255);
  font-weight: 800;
  font-size: 0.8rem;
  /* font-style: italic; */
  text-align: center;
  font-family: "Roboto", sans-serif;
  display: block;
  /* padding-bottom: 0.8rem; */
}
@media (max-width: 1100px) {
  .testemonial {
    width: 40vw;
    min-height: 14rem;
  }
}
@media (max-width: 800px) {
  .testemonial {
    width: calc(100vw - 6rem);
    /* min-height: 14rem; */
    /* height: 14rem; */
  }
}
@media (max-width: 500px) {
  .testemonial {
    /* min-height: 16rem; */
    /* height: 16rem; */
  }
}
#clear {
  z-index: 100;
  position: relative;
}
.heroimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  display: block;
}

/* body {
  overflow-y: hidden;
} */
.page-trans {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  background-color: rgb(0, 0, 0);
}
#services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  min-height: 65vh;
}

#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  /* min-height: 65vh; */
}
#contact {
  /* min-height: 65vh; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}
#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  /* min-height: 65vh; */
}

.video-container {
  width: 80vw;
  position: relative;
}
#hero {
  width: 100%;
  z-index: -11;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.blend {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;

  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.301), 60%, rgb(0, 0, 0));
}

.hero-content {
  /* z-index: 98; */
  padding-left: 1.5rem;
  margin-bottom: 5.6rem;
}
.p-hero {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.headline-1 {
  color: #eee;
  font-size: 4vw;
  font-family: "Poppins", sans-serif;
  transform: translateY(-1rem);
}

.headline-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 10vw;
  margin-top: 0rem;
  z-index: 0;
  position: relative;
  padding-bottom: 15rem;
}

.big-txt {
  font-size: 7vw;
  font-family: "Roboto", sans-serif;
  margin-bottom: 4rem;
  width: 100%;
  z-index: 0;
  position: relative;
}
.nosee {
  color: rgba(255, 255, 255, 0.509);
}

.md-txt {
  font-size: 5vw;
  font-family: "Arvo", serif;
  width: 80%;
  padding-left: 10%;
  margin-bottom: 6rem;
  z-index: 0;
  position: relative;
}
.see {
  color: white;
}
@media (max-width: 400px) {
  .headline-section {
    padding-bottom: 0rem;
  }
  .md-txt {
    padding-top: 3rem;
  }
}
@media (max-width: 800px) {
  .headline-section {
    padding-bottom: 10rem;
  }
  .nav-list {
    display: none;
  }
  .video-container {
    width: 100vw;
  }
  .heroimg {
    width: 120vw;
  }
  .video-container {
    overflow: hidden;
  }
  #hero {
    width: 120vw;
    /* margin-top
    : 20vh; */
    overflow: hidden;
  }
  video {
    width: 105vw;
  }
  .overlay {
    width: 100vw;
    opacity: 0.7;
  }
  .blend {
    opacity: 1;
    width: 100vw;
  }
  .headline-1 {
    font-size: 9vw;
  }
  .hero-content {
    left: 2rem;
  }
  /* .big-txt {
    font-size: 8vw;
  }
 */
  .md-txt {
    margin-bottom: 2rem;
  }
}

.wrap {
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  margin-top: 1rem;
}

.button {
  font-size: 14px;
  font-family: "Lexend", sans-serif;
  padding: 0.5rem;
  font-weight: 700;
  color: #ffffff;
  background: #ffffff00;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(238, 238, 238, 0) 100%
  );
  border: 3px solid white;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(51, 51, 51, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  transform: translateY(0.3rem);
}

/* button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(205px + 12px);
  min-height: calc(50px + 12px);
  border: 2px solid #00000000;
  box-shadow: 0 0 60px rgba(255, 255, 255, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
} */

.button:hover,
.button:focus {
  color: #ffffff;
  transform: translateY(-6px);
}

.button:hover::before,
.button:focus::before {
  opacity: 1;
}

.button::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 3px solid #ffffff;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.button:hover::after,
.button:focus::after {
  animation: none;
  display: none;
}
.hide {
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 0;
  }
  1% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }
}
.hide-mob {
  display: block;
}
.show-mob {
  display: none;
}

@media (max-width: 1200px) {
  .p-hero1 {
    margin-top: 2rem;
  }
  .p-hero2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  .hero-container {
    height: 90vh;
  }
  .hero-para {
    height: 10vh;
    z-index: 100;
  }
}
.auto-height {
  height: auto;
}
@media (min-width: 800px) {
  .hero-content {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 2rem;
  }
  .hero {
    position: relative;

    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
  .button {
    font-size: 14.4px;
  }
  .hide {
    display: block;
  }
}
@media (max-width: 800px) {
  .hero-content {
    padding-top: 1rem;
  }
  .show-mob {
    display: block;
  }
  .hide-mob {
    display: none;
  }
  .big-txt {
    font-size: 10vw;
    margin-bottom: 1rem;
  }
  .md-txt {
    font-size: 7vw;
  }
  .p-hero {
    font-size: 0.8rem;
  }
  @keyframes ring {
    0% {
      width: 20px;
      height: 20px;
      opacity: 1;
    }
    100% {
      width: 100px;
      height: 100px;
      opacity: 0;
    }
  }
}

/* .wrap-head {
  transform: translateY(20%);
  height: 100vh;
}
.line-head {
  width: 100vw;
}
.left-head,
.right-head {
  width: 50vw;
  overflow: hidden;
  display: inline-block;
}
.left-head {
  color: rgb(255, 255, 255);
  transform: skew(0deg, -15deg);
}
.right-head {
  color: rgb(87, 87, 87);
  transform: skew(0deg, 15deg);
}
.left-head .content-head {
  width: 100vw;
  text-align: center;
}
.right-head .content-head {
  width: 100vw;
  text-align: center;
  transform: translate(-50vw);
}
.span-head {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 10vw;
  text-transform: uppercase;
  line-height: 0.8;
  transition: ease-out 0.6s;
} */
.carzy-headline {
  width: 100%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin-top: 5rem;
  margin-bottom: 10rem;
}
.carzy-headline h1 {
  font-size: 9.5vw;
  color: rgb(60, 60, 60);
  font-family: "Roboto", sans-serif;
}
@media (max-width: 1200px) {
  .carzy-headline {
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
  .carzy-headline h1 {
    color: rgb(60, 60, 60);
    font-family: "Roboto", sans-serif;
  }
}
@media (max-width: 800px) {
  .carzy-headline {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .carzy-headline h1 {
    font-size: 12vw;
  }
}

.disnone {
  display: none;
}
