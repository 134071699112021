.action-quote {
  width: 100%;
  background-color: rgb(36, 94, 195);
  border: none;
  padding: 0.3rem;
  color: #fff;
  border-radius: 4px;

  font-family: "Lexend", sans-serif;
  margin-top: 1rem;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.action-quote:active {
  width: 100%;
  background-color: rgb(36, 94, 195);
  border: none;
  padding: 0.3rem;
  color: #fff;
  border-radius: 4px;

  font-family: "Lexend", sans-serif;
  margin-top: 1rem;
  transition: 0.2s ease-in;
}
.action-quote:hover {
  /* background-color: rgb(73, 73, 73); */
  transform: translateY(-0.2rem);
  transition: 0.2s ease-in;

  font-family: "Lexend", sans-serif;
  margin-top: 1rem;
}
.ssvg {
  position: absolute;
  top: -4000px;
  left: -4000px;
}
.fix-button {
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
}

#gooey-button {
  padding: 0.4rem;
  width: 100%;
  margin-top: 4rem;
  font-size: 0.8rem;
  border: none;
  /* font-weight: bold; */
  filter: url("#gooey");
  position: relative;
  cursor: pointer;
  background-color: rgb(36, 94, 195);

  transform: translateY(0px);
  transition: transform 0.2s ease-in;
}
#gooey-button:hover,
#gooey-button:hover .bubbles .bubble {
  transition: 0.2s ease-in;
  transform: translateY(-4px);
  /* background-color: rgb(36, 94, 195); */
}
#gooey-button:focus {
  outline: none;
}
#gooey-button .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#gooey-button .bubbles .bubble {
  transition: transform 0.2s ease-in;
  transform: translateY(0px);
  background-color: rgb(36, 94, 195);

  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: -1;
}
#gooey-button .bubbles .bubble:nth-child(1) {
  left: 27px;
  width: 25px;
  height: 25px;
  animation: move-1 3.02s infinite;
  animation-delay: 0.2s;
}
#gooey-button .bubbles .bubble:nth-child(2) {
  left: 16px;
  width: 25px;
  height: 25px;
  animation: move-2 3.04s infinite;
  animation-delay: 0.4s;
}
#gooey-button .bubbles .bubble:nth-child(3) {
  left: 86px;
  width: 25px;
  height: 25px;
  animation: move-3 3.06s infinite;
  animation-delay: 0.6s;
}
#gooey-button .bubbles .bubble:nth-child(4) {
  left: 72px;
  width: 25px;
  height: 25px;
  animation: move-4 3.08s infinite;
  animation-delay: 0.8s;
}
#gooey-button .bubbles .bubble:nth-child(5) {
  left: 86px;
  width: 25px;
  height: 25px;
  animation: move-5 3.1s infinite;
  animation-delay: 1s;
}
#gooey-button .bubbles .bubble:nth-child(6) {
  left: 63px;
  width: 25px;
  height: 25px;
  animation: move-6 3.12s infinite;
  animation-delay: 1.2s;
}
#gooey-button .bubbles .bubble:nth-child(7) {
  left: 100px;
  width: 25px;
  height: 25px;
  animation: move-7 3.14s infinite;
  animation-delay: 1.4s;
}
#gooey-button .bubbles .bubble:nth-child(8) {
  left: 73px;
  width: 25px;
  height: 25px;
  animation: move-8 3.16s infinite;
  animation-delay: 1.6s;
}
#gooey-button .bubbles .bubble:nth-child(9) {
  left: 83px;
  width: 25px;
  height: 25px;
  animation: move-9 3.18s infinite;
  animation-delay: 1.8s;
}
#gooey-button .bubbles .bubble:nth-child(10) {
  left: 70px;
  width: 25px;
  height: 25px;
  animation: move-10 3.2s infinite;
  animation-delay: 2s;
}

@keyframes move-1 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -76px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-2 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -98px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-3 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -87px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-4 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -94px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-5 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -114px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-6 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -70px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-7 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -67px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-8 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -53px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-9 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -72px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-10 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -73px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
.toto {
  position: relative;
  height: 100%;
  width: 100%;
}
.swiper {
  /* margin: 100px auto; */
  width: 80vw;
  /* background-color: rgb(184, 0, 0); */
  /* height: 30rem; */
  margin: 1rem;
}

.swiper-slide {
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
