.contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3rem;
}
/* .contact-h1 {
  color: white;
  font-family: "Roboto", sans-serif;
  margin: 2rem;
  font-size: 2rem;
  margin-bottom: 0.8rem;
} */
.contact-info {
  width: 50%;
}
.contact-info span {
  padding-top: 0.16rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  line-height: 150%;
  color: rgb(216, 216, 216);
}
.info {
  margin: 1.6rem;
}
.tele {
  display: flex;
}
.tele div {
  display: flex;
}
.mail {
  display: flex;
  align-items: center;
  align-content: center;
}

.contact-form {
  padding: 4rem;
  padding-top: 0;
  width: 50%;
}
.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.contact-form form div {
  display: flex;
  flex-direction: column;
}
.contact-form form div label {
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;
}
.contact-form form div span {
  color: white;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.96rem;
  margin-top: -0.8rem;
}
.contact-form form div input,
.contact-form form div textarea {
  margin-bottom: 0.96rem;
  padding: 0.48rem;
  border-radius: 4px;
  background-color: rgb(47, 50, 53);
  border: none;
  caret-color: rgb(46, 123, 255);
  color: rgb(181, 181, 181);
  font-size: 0.8rem;
}
.contact-form form div input:focus,
.contact-form form div textarea:focus {
  outline: none;
  border-bottom: 2px rgb(46, 123, 255) solid;
}
.contact-button {
  padding: 0.4rem;
  background-color: rgb(46, 123, 255);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-button span {
  margin-left: 0.56rem;
  font-size: 0.8rem;
  font-weight: bold;
}
.contact-button:hover {
  background-color: rgb(0, 71, 194);
  transition: 0.2s ease;
}

.title-left {
  margin-left: 1.6rem;
}
.mgdwn {
  margin-bottom: 1.76rem;
  margin-top: -1.6rem;
}
.faq {
  width: 100%;
  /* padding-top: 3rem; */
}
.left-contact-wrap {
  padding: 4rem;
  padding-top: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact-details {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0.8rem;
  height: 100%;
}
.contact-item {
  color: rgb(46, 123, 255);
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.747);
  padding: 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
}
.contact-item:hover {
  background-color: rgb(0, 0, 0);
  /* color: rgb(255, 255, 255); */
}
.contact-item a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;

  color: rgb(46, 123, 255);
  padding-left: 0.8rem;
}
.details-container {
  background-color: rgb(47, 50, 53);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.social {
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;
}
.d-container {
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
}
.social a {
  /* padding: 1.5rem; */
  height: 100%;
  width: 100%;
  color: white;
  font-size: large;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.788); */
  transition: 0.3s ease-in-out;
}
.social a:nth-child(1):hover {
  background-color: #4267b2;
}
.social a:nth-child(2):hover {
  background-color: #c4302b;
}
.social a:nth-child(3):hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.social a:nth-child(4):hover {
  background-color: #00acee;
}
.social a:nth-child(5):hover {
  background-color: #0072b1;
}

@media (max-width: 1200px) {
  .contact {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .contact-form {
    width: 100%;
    padding: 3.5rem;
    padding-top: 0;
  }
  .faq {
    width: 100%;
    padding: 3.5rem;
    padding-top: 0;
  }
  .details-container {
    /* margin-bottom: 0rem; */
    flex-direction: column;
  }
  .d-container {
    margin: 3.5rem;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
  }
  .contact-details {
    width: 100%;
  }
  .left-contact-wrap {
    width: 100%;
    padding: 0;
  }
  .social {
    width: 100%;
    flex-wrap: wrap;
  }
  .social a {
    padding: 0.8rem;
    border-radius: 4px;
    margin: 0.3rem;
    width: calc(50% - 0.6rem);
  }
  .social a:nth-child(1) {
    background-color: #4267b2;
  }
  .social a:nth-child(2) {
    background-color: #c4302b;
  }
  .social a:nth-child(3) {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
  .social a:nth-child(4) {
    background-color: #00acee;
  }
  .social a:nth-child(5) {
    background-color: #0072b1;
    width: calc(100% - 0.6rem);
  }
}

@media (max-width: 800px) {
  .contact {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .contact-info {
    width: 90%;
  }
  .contact-form {
    width: 100%;
    padding: 3.5rem;
    padding-top: 0;
  }
  .faq {
    width: 100%;
    padding: 3.5rem;
    padding-top: 0;
  }
  .contact-h1 {
    font-size: 1.5rem;
  }
  .contact-info span {
    font-size: 0.8rem;
  }
}
.copyright {
  font-size: 0.8rem;
  color: white;
  font-family: "Lexend", sans-serif;
  text-align: center;
  margin-bottom: 1rem;
  /* display: none; */
}
.link {
  color: rgb(46, 123, 255);
  text-decoration: underline;
  cursor: pointer;
}
