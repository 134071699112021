/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.qoute-services-container p {
  font-size: 0.8rem;
  font-weight: lighter;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
.fonting {
  font-family: "Roboto", sans-serif;
}
.qoute-services-container {
  /* display: flex; */
  /* display: flex; */
  width: 100%;
  padding: 0.5rem;
}
.qoute-services-sub-container {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
  flex-wrap: wrap;
}
.quote-service-wrap {
  background-color: rgb(56, 62, 70);
  padding: 1.2rem;
  margin: 0.5rem;
  border-radius: 6px;
  width: calc(100% / 3 - 2rem);
  position: relative;
  cursor: pointer;
  border: rgba(46, 123, 255, 0) 2px solid;
}
.service-selected {
  border: rgb(46, 123, 255) 2px solid;
  transform: scale(98%);
  /* transition: 0.2s ease; */
}
.quote-service-wrap h4 {
  color: rgb(46, 123, 255);
  font-size: 1rem;
  align-items: center;
  display: flex;
}
.quote-service-wrap h4 span {
  margin-right: 0.5rem;
}
.quote-service-wrap div {
  font-family: "Lexend", sans-serif;
  font-weight: lighter;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  margin-top: 1rem;
}
.checked-s {
  color: rgb(46, 123, 255);
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
}
.disable-click {
  pointer-events: none;
}
.sn-quote-container {
  display: flex;
  flex-wrap: wrap;
}
.sn-quote {
  padding: 0.4rem 0.8rem;
  color: white;
  margin: 0.3rem 0.2rem;
  font-size: 0.8rem;
  border-radius: 10000px;
  cursor: pointer;
  background-color: rgb(56, 62, 70);
  border: 2px solid rgba(46, 123, 255, 0);
  transition: 0.2s ease;
}
.sn-quote1 {
  padding-bottom: 0.8rem;
  color: white;
  margin: 0.3rem 0.2rem;
  font-size: 0.8rem;
  border-radius: 5px;
  width: 50%;
  cursor: pointer;
  background-color: rgb(56, 62, 70);
  border: 2px solid rgba(46, 123, 255, 0);
  transition: 0.2s ease;
}
.border-it {
  border: 2px solid rgb(46, 123, 255);
  transition: 0.2s ease;
  /* transform: scale(calc(98%)); */
}
.white {
  color: white;
}
.wrap-it div {
  color: white;
}
.cn-title {
  color: rgb(46, 123, 255);
  font-weight: bold;
  font-size: 0.9rem;
  text-align: left;

  margin-right: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}
.ssc {
  display: flex;
  flex-direction: column;
}
.shifter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
}
.shifter div {
  width: 100%;
  padding: 0.4rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgb(56, 62, 70);
  text-align: center;
}
.wrap-it {
  display: flex;
  width: 100%;
}
.s-underline::after {
  /* transform: translateX(-100%); */
  content: "";
  width: calc(50%);
  height: 2px;
  /* margin-top: 0.7rem; */
  /* margin-left: -0.7rem; */
  /* margin-bottom: -0.7rem; */
  background-color: rgb(46, 123, 255);
  display: block;
  /* margin-left: auto; */
  transition: transform 0.4s ease;
}
.s-underline-right::after {
  /* transform: translateX(-100%); */
  content: "";
  width: calc(50%);
  height: 2px;
  /* margin-top: 0.7rem; */
  /* margin-left: -0.7rem; */
  /* margin-bottom: -0.7rem; */
  background-color: rgb(46, 123, 255);
  display: block;
  /* margin-left: auto; */
  transform: translateX(100%);
  transition: transform 0.4s ease;
}
.desc-ds-title {
  font-family: "Poppins", sans-serif;
  /* font-weight: lighter; */
  font-style: italic;
  padding: 1rem;
}
.edit-it {
  background-color: rgba(0, 0, 0, 0.336);
  width: 100%;
  padding: 1rem;
  color: rgb(213, 213, 213);
  border-radius: 5px;
}
.arrange-list {
  display: flex;
  flex-direction: column;
}
.ds-list-item {
  font-family: "Lexend", sans-serif;
  color: rgb(199, 209, 217);
  padding-left: 1rem;
}
.section-desc-head {
  color: white;
  font-family: "Roboto", sanas-serf;
  font-size: 1.3rem;
  padding: 1.4rem;
}

@media (max-width: 900px) {
  .quote-service-wrap {
    width: 100%;
  }
  .sn-quote1 {
    width: 100%;
  }
  .qoute-services-container h3 {
    margin-left: 1rem;
    font-size: 1.4rem;
  }
}
