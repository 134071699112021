.profile-container {
  padding: 3.2rem;
  width: 100%;
  padding-top: 4.5rem;
  position: absolute;
  top: 0;
  left: 0;
}
.profile {
  display: flex;
  justify-content: flex-start;
}
.profile-details {
  padding: 2rem;
  padding-left: 3rem;
}
.profile-link-container {
  color: white;
  font-family: "Lexend", sans-serif;
  display: flex;
  align-items: center;
}
.profile-home {
  color: white;
  font-family: "Lexend", sans-serif;
  display: flex;
  align-items: center;
  text-decoration: none;

  transition: all 0.2s ease-in-out;
}
.profile-home:hover {
  transform: translateY(-1px);
  transition: all 0.2s ease-in-out;
}
.profile-home span {
  padding-right: 0.3rem;
  font-size: 0.8rem;
}
.proflie-picture {
  height: 12rem;
  width: 12rem;
  border-radius: 12rem;
  background-size: cover;
  background-color: rgb(47, 50, 53);
}
.profile-name {
  color: rgb(46, 123, 255);
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  padding-bottom: 0.4rem;
}
.profile-role {
  color: rgb(213, 213, 213);
  font-family: "Lexend", sans-serif;
  font-size: 0.8rem;
}
.profile-contact {
  background-color: rgb(47, 50, 53);
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}
.profile-contact a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: rgb(226, 226, 226);
  padding: 1rem 2rem;
  font-size: 0.9rem;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}
.profile-contact a:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgb(18, 19, 20);
}
.profile-contact a span {
  font-family: "Roboto", sans-serif;
  padding-left: 0.5rem;
}
.save-contact {
  width: 100%;
  text-decoration: none;
  background-color: rgb(46, 123, 255);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Roboto", sans-serif;
  transition: all 0.2s ease-in-out;
  display: flex;
}
.linkvcard {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
  width: 100%;
}
.bio-text {
  color: rgb(208, 208, 208);
  font-size: larger;
}
.save-contact:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgb(0, 94, 255);
}
.portofolio-images-wrap {
  display: flex;
  justify-content: flex-start;
  gap: 0.2rem;
}
.portofolio-image {
  width: 20vw;
  height: 20vw;
  background-color: rgb(47, 50, 53);
  background-size: cover;
  background-position: center center;
}
.certificate-links-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.certificate-link {
  width: calc(50vw - 6rem);
  border-radius: 4px;
  height: 4rem;
  background-color: rgb(47, 50, 53);
  margin: 1rem;
}
@media (max-width: 1250px) {
  .profile-name {
    font-size: 1.4rem;
    padding-bottom: 0rem;
  }
  .profile-role {
    font-size: 0.7rem;
  }
  .profile-container {
    margin-top: 3.5rem;
  }
  .profile-contact a {
    font-size: 0.8rem;
  }
  .profile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-details {
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0;
    padding-top: 1.3rem;
  }
  .profile-contact {
    flex-wrap: wrap;
    width: 100%;
  }
  .profile-contact a {
    text-align: center;
    justify-content: center;
    width: 100%;
    width: 25%;
  }
}
@media (max-width: 1050px) {
  .profile-contact {
    flex-wrap: wrap;
    width: 100%;
  }

  .profile-contact a {
    text-align: center;
    justify-content: center;
    width: 100%;
    width: 50%;
  }
}
@media (max-width: 900px) {
  .portofolio-images-wrap {
    flex-wrap: wrap;
  }
  .portofolio-image {
    width: calc((100vw / 3) - 2.7rem);
    height: calc((100vw / 3) - 2.7rem);
    /* margin: 0.1rem; */
  }
  .profile-contact {
    flex-wrap: wrap;
  }
  .profile-contact a {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 700px) {
  .portofolio-image {
    width: calc(50vw - 3.7rem);
    height: calc(50vw - 3.7rem);
  }
  .profile-container {
    padding: 3rem;
  }
}
