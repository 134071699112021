.projects-container {
  width: 100%;
  padding: 4.8rem;
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
}
.project-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20rem;
  margin: 0.8rem;
  background-color: rgb(47, 50, 53);
  box-shadow: rgb(16, 16, 16) 0px 0px 6px;
  border-radius: 4px;
}
.project-image {
  width: 100%;
  position: relative;
  height: 12rem;
  background-color: rgb(33, 33, 33);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.project-details {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
}
.project-title {
  color: rgb(46, 123, 255);
  font-weight: bold;
  font-size: 1.04rem;
  margin-bottom: 0.24rem;
  font-family: "Poppins", sans-serif;
}
.project-desc {
  color: white;
  font-family: "Poppins", sans-serif;

  font-size: 0.8rem;
  margin-bottom: 0.24rem;
}
.project-status-wrap {
  margin-top: 0.48rem;
  margin-bottom: 0.48rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.6rem;
}
.project-status {
  font-family: "Roboto", sans-serif;
  border-radius: 8px;
  color: rgb(46, 123, 255);
  border: 1px solid rgb(46, 123, 255);
  background-color: rgba(46, 123, 255, 0.229);
  font-weight: bold;
  padding: 0.24rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: inherit;
  font-size: 0.72rem;
}
.project-price {
  font-family: "Poppins", sans-serif;
  /* margin-bottom: 0.3rem; */
  color: rgb(213, 213, 213);
  font-size: 0.64rem;
  margin-left: 0.48rem;
}

.prj-qn-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  margin-bottom: 4.8rem;
}
.prj-qn-div h1 {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
}
.prj-qn-div button {
  margin-left: 1.6rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background: none;
  font-family: "Lexend", sans-serif;
  border-radius: 1000px;
  border: 3.2px solid white;
  padding: 0.4rem;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}
.prj-qn-div button:hover {
  transform: translateY(-6px);
}
.project-image-1 {
  background-image: url(../images/p2.png);
  background-size: cover;
  background-position: top;
}
.project-image-2 {
  background-image: url(../images/p1.jpg);
  background-size: cover;
  background-position: top;
}
@media (max-width: 800px) {
  .projects-container {
    /* flex-direction: column; */
    padding: 2.7rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    flex-wrap: wrap;
    /* padding-bottom: 0; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .project-wrap {
    width: 17rem;

    margin-top: 0rem;
    margin-bottom: 3rem;

    padding-top: 0;
  }
  .project-image {
    height: 10rem;
  }
  .project-title {
    font-size: 0.9rem;
  }
  .project-desc {
    font-size: 0.7rem;
  }
  .project-status {
    border: 0.8px solid rgb(46, 123, 255);
    padding: 0.16rem;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    width: inherit;
    font-size: 0.64rem;
    text-align: center;
  }
  .project-price {
    font-size: 0.56rem;
  }
  .project-status-wrap {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .prj-qn-div {
    flex-direction: column;
  }
  .prj-qn-div h1 {
    font-size: 1.8rem;
    padding: 3.5rem;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }
  .prj-qn-div button {
    margin: 3.5rem;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    border: 3px solid white;
  }
}
@media (max-width: 720px) {
  .project-wrap {
    width: 25rem;

    margin-top: 0rem;
    margin-bottom: 3rem;

    padding-top: 0;
  }
}
