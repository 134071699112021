@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.logo {
  height: 2rem;
}

.nav-styles {
  display: flex;
  z-index: 99;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.137);
  padding: 0.5rem 1.6rem;
  transition: 0.2s ease-in-out;
}
#logo {
  font-family: "Cabin", sans-serif;
  color: #eee;
  font-size: 1.28rem;
  /* margin: 0.8rem;
  margin-left: 2rem; */
}
.rm-list-style {
  list-style: none;
}
.nav-list {
  display: flex;
  justify-content: right;

  /* margin-top: 0.8rem;
  margin-right: 2rem; */
}
.nav-item {
  margin: 0.32rem;
  margin-bottom: 0;
  padding: 0.32rem;
  padding-bottom: 0;
  padding-top: 0;
  cursor: pointer;
}

.nav-link {
  text-decoration: none;
  transition: width 0.3s;
}

.nav-name {
  font-family: "Lexend", sans-serif;
  color: #eee;
  font-size: 0.68rem;
  font-weight: 400;
}

.nav-link span::after {
  transition: width 0.3s;
  content: "";
  display: block;
  margin-top: 1px;
  width: 0;
  height: 1.2px;
  border-radius: 0.75;
  background-color: #eee;
}
.nav-link span:hover:after {
  width: calc(100%);
  transition: width 0.3s;
}
.nav-active span::after {
  width: calc(100%);
  transition: width 0.3s;
}
.nav-active span {
  font-weight: 500;
}
.shrink {
  background-color: rgb(0, 0, 0);
}
@media (max-width: 800px) {
  .logo {
    height: 2.1rem;
    padding: 0.01rem;
  }
  #nav {
    padding: 0.8rem 1.3rem;
  }
  .nav-styles {
    position: absolute;
    transition: none;
    background-color: rgba(0, 0, 0, 0);
  }
}
