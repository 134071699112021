.service {
  background-color: rgb(47, 50, 53);
  font-family: "Roboto", sans-serif;
  color: rgb(46, 123, 255);
  margin: 0.8rem;
  background-image: none;
  /* margin-bottom: 0rem; */
  width: calc(25% - 3.2rem);
}
.services-container {
  display: flex;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 4.5rem;
}
.section-title {
  color: whitesmoke;
  font-family: "Roboto", sans-serif;
  text-align: center;

  font-size: 1.6rem;
}
.section-title::after {
  content: "";
  margin: auto;
  margin-top: 0.64rem;
  margin-bottom: 2rem;
  height: 0.24rem;
  border-radius: 0.12rem;
  width: 0rem;
  background-color: rgb(46, 123, 255);
  display: block;
  transition: width 0.4s ease-in;
  /* transition-delay: 0.5s; */
}
.show-line::after {
  width: 6.4rem;
}
.p-s {
  padding-top: 0.16rem;
  padding-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
  /* background-color: aliceblue; */
  height: calc(100% - 2rem);
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  color: rgb(213, 213, 213);
  opacity: 0;
  transition: 0.4s ease-in;
  white-space: pre-wrap;
}

.animate-p-s {
  opacity: 1;
}

.s-name h1 {
  font-size: 0.96rem;

  padding-right: 0.4rem;
  align-items: center;
  text-align: center;
}
.s-name span {
  font-size: 0.88rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.s-name {
  display: flex;
  justify-content: center;
  padding: 0.8rem;
  align-items: center;
}
.move-s-t-left {
  justify-content: left;
  padding-left: 0;
}

.title-underline {
  border: none;
  width: 6rem;
  margin: auto;
  background-color: rgb(46, 123, 255);
  height: 0.2rem;
  margin-bottom: 2.5rem;
  margin-top: 0.8rem;
}

@media (max-width: 1050px) {
  .s-name h1 {
    font-size: 0.9rem;
  }

  .p-s {
    font-size: 0.7rem;
  }
}
@media (max-width: 1000px) {
  .service {
    width: calc(50% - 2rem);
  }
  .s-name h1 {
    font-size: 0.96rem;
  }

  .p-s {
    font-size: 0.8rem;
  }
  .services-container {
    padding: 3.5rem;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}
@media (max-width: 610px) {
  .s-name h1 {
    font-size: 0.9rem;
  }
  .section-title {
    font-size: 1.4rem;
  }
  .p-s {
    font-size: 0.7rem;
  }
}
@media (max-width: 570px) {
  .service {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .s-name h1 {
    font-size: 0.96rem;
  }
  .section-title {
    font-size: 1.6rem;
  }

  .p-s {
    font-size: 0.8rem;
  }
}
@media (max-width: 400px) {
  .s-name h1 {
    font-size: 0.9rem;
  }
  .section-title {
    font-size: 1.4rem;
  }
  .p-s {
    font-size: 0.7rem;
  }
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

.card {
  padding: 3px;
  position: relative;
  /* border-radius: 6px; */
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  display: flex;
  font-size: 1.5em;
  /* color: rgb(88 199 250 / 0%); */
  cursor: pointer;
  /* font-family: cursive; */
}

/* .card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
} */
/* .card:hover:before,
.card:hover:after {
  animation: none;
  opacity: 0;
} */

.card::before {
  content: "";
  width: 102%;
  height: 102%;
  border-radius: 20px;
  background-image: linear-gradient(
    var(--rotate),
    #3971ff,
    #3c67e3 43%,
    #ff3b3b
  );
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -1%;
  animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.6);
  filter: blur(calc(var(--card-height) / 4));
  /* background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  ); */
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
