.blue-nav {
  position: fixed;
  bottom: 0.7rem;
  left: 0.7rem;
  z-index: 1280;
}

.red-nav {
  position: fixed;
  bottom: 0.7rem;
  right: 0.7rem;
  z-index: 1280;
}
.mob-nav-list {
  width: 100%;
  display: flex;

  justify-content: space-between;
  /* background-color: rgb(49, 49, 49); */
  margin: 0rem;
  /* padding: 1.2rem; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  align-items: center;
}

.mob-nav-list-item {
  /* list-style: none; */
  font-family: "Lexend", sans-serif;
  /* text-align: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.mob-nav-link {
  color: rgb(46, 123, 255);
  display: flex;
  background-color: rgb(26, 26, 26);
  width: 100%;
  /* transition-delay: 0.3s; */
  transition: all 0.2s ease-in;
  align-items: center;
  justify-content: left;
  border: 2px rgb(46, 123, 255) solid;

  /* padding: 1rem; */
  /* background: chartreuse; */
  border-radius: 200000px;
  overflow: hidden;
  /* margin: 0.5rem; */
  /* cursor: pointer; */
  /* max-width: 100px; */
  /* transition: max-width 1s ease-out; */
  /* transition: width 2s ease-out; */
  /* transition: max-width 0.3s ease-out; */
}
.mob-nav-link-two {
  flex-direction: row-reverse;
  color: rgb(255, 47, 47);
  display: flex;
  background-color: rgb(26, 26, 26);
  width: 100%;
  /* transition-delay: 0.3s; */
  transition: all 0.2s ease-in;
  align-items: center;
  justify-content: left;
  border: 2px rgb(255, 47, 47) solid;

  /* padding: 1rem; */
  /* background: chartreuse; */
  border-radius: 200000px;
  overflow: hidden;
  /* margin: 0.5rem; */
  /* cursor: pointer; */
}
.mob-nav-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  /* padding: 0.6rem; */
}
.mob-nav-icon-two {
  z-index: 1000000000000000000;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  /* padding: 0.6rem; */
  /* background-color: rgb(26, 26, 26); */
}

.mob-nav-item-text {
  max-width: 0;
  /* transition: width 0.3s ease-in; */
  /* opacity: 0; */
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
  /* margin-left: 1rem; */
  /* margin: 0.3rem; */
  /* opacity: 0; */
  /* transform: translateX(5px); */
  opacity: 0;
  transition: opacity 0.1s ease-out, max-width 0.5s ease-out,
    padding 0.5s ease-out;
  /* transition-delay: 0.2s; */
  text-align: justify;
}
.mob-nav-item-text-two {
  max-width: 0;
  /* transition: width 0.3s ease-in; */
  /* opacity: 0; */
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
  /* margin-left: 1rem; */
  /* margin: 0.3rem; */
  /* opacity: 0; */
  /* transform: translateX(5px); */
  opacity: 0;
  transition: opacity 0.1s ease-out, max-width 0.5s ease-out,
    padding 0.5s ease-out;
  /* transition-delay: 0.2s; */
  text-align: justify;
}
.show-nav-text {
  color: rgb(46, 123, 255);
  cursor: pointer;
  background-color: rgb(32, 32, 32);

  transition: all 0.1s ease-in;
  /* transition-delay: 0.3s; */
  /* max-width: 100px; */
  /* transition: width 0.3s ease-out; */
}
.show-nav-text-two {
  cursor: pointer;
  color: rgb(255, 47, 47);

  background-color: rgb(32, 32, 32);

  transition: all 0.1s ease-in;
  /* transition-delay: 0.3s; */
  /* max-width: 100px; */
  /* transition: width 0.3s ease-out; */
}

.show-nav-text .mob-nav-item-text {
  text-align: justify;
  transition: opacity 0.5s ease-in, max-width 0.5s ease-in, padding 0.5s ease-in;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.2rem;
  margin-right: 0.3rem;
  border-radius: 1000px;

  /* transition-delay: 0.2s; */
  max-width: 200px;
  background-color: black;
  opacity: 1;
}
.show-nav-text-two .mob-nav-item-text-two {
  text-align: justify;
  transition: opacity 0.5s ease-in, max-width 0.5s ease-in, padding 0.5s ease-in;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.2rem;
  margin-right: 0.3rem;
  border-radius: 1000px;

  /* transition-delay: 0.2s; */
  max-width: 200px;
  background-color: black;
  opacity: 1;
}
.show-nav-text-two .fix-padding-red {
  margin-left: 0.6rem;
}
.show-nav-text .fix-padding-blue {
  margin-right: 0.6rem;
}
.menu-unchanged {
  transform: translateX(0);
  transition: 0.35s ease-in;
}
.menu-one-out {
  transform: translateX(-70px);
  transition: 0.35s ease-in;
}
.menu-two-out {
  transform: translateX(70px);
  transition: 0.35s ease-in;
}

@media (min-width: 800px) {
  .mob-nav-container {
    display: none;
  }
}
