.team-paper {
  margin: 0.8rem;
  width: calc(25vw - 3.2rem);
  padding: 0.6rem;
  background-color: rgb(0, 0, 0);
  box-shadow: 0px 0px 3px rgb(187, 187, 187);
  /* margin: 0 0.4rem; */
  border-radius: 4px;
  text-decoration: none;
  /* cursor: pointer; */
}

.team-head {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
.team-head-text {
  margin-left: 1rem;
}
.team-head-text span {
  padding-top: 0.16rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 0.88rem;
}
.team-desc {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  /* border-top: 1px solid rgb(255, 255, 255); */
  padding-top: 0.8rem;
}
.team-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding: 6rem; */
}
/* .bye {
  opacity: 1;
  transform: scale(1);
} */
@media (max-width: 1200px) {
  .team-paper {
    margin: 0.8rem;
    width: calc(50vw - 6rem);
    padding: 0.6rem;
    background-color: rgb(0, 0, 0);
    box-shadow: 0px 0px 3px rgb(187, 187, 187);
    /* margin: 0 0.4rem; */
    border-radius: 4px;
    /* cursor: pointer; */
  }
  .team-container {
    width: 100%;
    padding: 3.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 800px) {
  .team-container {
    width: 100%;
    justify-content: center;
    padding: 2.7rem;
    padding-top: 0rem;
  }
  .team-paper {
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .team-desc {
    font-size: 0.8rem;
  }
  .team-head-text span {
    font-size: 0.9rem;
  }
}
