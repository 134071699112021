.about-p1 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.88rem;
}
.left-end {
  text-align: left;
}
.left-end::after {
  margin-left: 0;
}
.about-container {
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  flex-wrap: wrap;
  margin-top: -5rem;
  margin-bottom: 6rem;
}
.about-text {
  width: 50%;
  padding: 4rem;
  /* padding-bottom: rem; */
}
.red::after {
  background: rgb(255, 47, 47);
}
/* 
#canvas {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
} */
/* @media (max-width: 1124px) {
  .about-text {
    width: 58%;
  }
  .sec1-about {
    width: 90%;
  }
} */
@media (max-width: 1200px) {
  .about-container {
    justify-content: center;
    flex-direction: column;
  }
  .about-text {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .about-container {
    justify-content: center;
    flex-direction: column;
  }
  .sec1-about {
    width: 85%;
  }
  .about-text {
    width: 100%;
    /* padding: 3.5rem; */
    padding: 3.5rem;

    padding-bottom: 3.5rem;
    padding-top: 0;
  }

  .about-p1 {
    font-size: 0.8rem;
  }
}
