@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap"); /* body {
  overflow-y: hidden;
  z-index: 1301;
} */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-delay: 0.5s;
  padding: 2rem;
  overflow: hidden;
  /* display: none; */
}

.cursor {
  animation: blink 1s infinite;
  height: 1ch;
  border-right: 1.5px solid rgba(87, 82, 82, 0);
  padding-right: 7px;
}
.line1 {
  font-family: "Open-sans", sans-serif;
  text-align: center;
  height: 1ch;
  font-weight: 600;
  /* background-color: aqua; */
}
.line2 {
  font-weight: 600;
  height: 1ch;
  font-family: "Open-sans", sans-serif;
  text-align: center;
}
.none {
  display: none;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.tips {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  color: white;
}
.pre-logo {
  height: 3.2rem;
  transform: translateY(-50%);
}
@media (max-width: 800px) {
  .line1 {
    font-size: 1.4rem;
    /* background-color: aqua; */
  }
  .line2 {
    font-size: 1.4rem;
  }
  .pre-logo {
    height: 2.4rem;
    transform: translateY(-50%);
  }
}
.remove-logo {
  transition: 0.5s ease;
  /* transition-delay: 1s; */
  opacity: 0;
  /* transform: translateY(calc(-50% - px)); */
}
.remove {
  transition: 0.5s ease;
  /* opacity: 0; */
  transform: translateY(-100%);
}
.hidethen {
  opacity: 0;
}
.appear {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.loader {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
    rgb(255, 46, 63),
    rgb(255, 46, 63),
    rgb(255, 46, 63)
  );
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
    rgb(46, 123, 255),
    rgb(0, 0, 0),
    rgb(255, 46, 63)
  );
}

.loader span:nth-child(1) {
  filter: blur(1px);
}

.loader span:nth-child(2) {
  filter: blur(4px);
}

.loader::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #000000;
  border-radius: 50%;
}

.prebutton {
  /* margin-left: 1.6rem; */
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translate(-50%, 8px);
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background: none;
  font-family: "Lexend", sans-serif;
  border-radius: 1000px;
  border: 3.2px solid white;
  padding: 0.4rem;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.prebutton:hover {
  transform: translate(-50%, -4px);
}
.showbtn {
  pointer-events: all;
  transition: 0.5s ease-in-out;
  opacity: 1;
  transform: translate(-50%, 0px);
}
